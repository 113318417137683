.react-datepicker {
	font-family: unset;
	font-size: 0.9rem;
}
.react-datepicker-popper {
	z-index: 9999;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
	display: block;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
	right: 90px;
}
.react-datepicker__navigation--previous {
	border-right-color: #a9afb2;
}
.react-datepicker__navigation--previous:hover {
	border-right-color: #8f9699;
}
.react-datepicker__navigation--next {
	border-left-color: #a9afb2;
}
.react-datepicker__navigation--next:hover {
	border-left-color: #8f9699;
}
.react-datepicker__header {
	background: #f0f1f2;
}
.react-datepicker__header,
.react-datepicker__time-container {
	border-color: #e2e8f0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	font-size: inherit;
	font-weight: 600;
}
.react-datepicker-year-header {
	padding-bottom: 8px;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	margin: 0 1px 0 0;
	height: auto;
	padding: 7px 10px;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	background: #edf2f7;
}
.react-datepicker__day:hover {
	background: #edf2f7;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background: #3182ce;
	font-weight: normal;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background: #2a69ac;
}
.react-datepicker__close-icon {
	padding-right: 14px;
	z-index: 2;
}
.react-datepicker__close-icon::after {
	background-color: unset;
	border-radius: unset;
	font-size: 16px;
	font-weight: bold;
	color: #808080;
	height: 20px;
	width: 20px;
}
.react-datepicker__close-icon:hover::after {
	color: #323232;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: #323232 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month--selected,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background: #323232 !important;
}
.react-datepicker__day:hover {
	background: #f0f1f2;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	background: #f0f1f2;
}
.react-datepicker__time-list-item--disabled {
	display: none;
}
